import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { drids } from '_constants/drids';

import { Page } from 'components/_shared/Page';

import { parseMarkdown, sortByKey } from 'utils';

export default function PagePeople(props) {
  const {
    allStrapiDevReveler: { nodes: devRevelers },
    strapiDevreveler: page
  } = props.data;

  const sortedDevRevelers = useMemo(() => {
    return sortByKey(devRevelers);
  }, [devRevelers]);

  const renderPeople = () => {
    return sortedDevRevelers.map(person => (
      <section
        id={person.anchor}
        key={person.profile_name}
        className='items-start'
      >
        <div className='flex space-x-3 md:space-x-6 mb-3 md:mb-6 max-h-hod'>
          {person.Story_image.map((photo, i) => {
            if (!photo) return null;
            return (
              <GatsbyImage
                key={i}
                image={getImage(photo.localFile)}
                alt={`${person.profile_name} ${++i}`}
                className={cn('filter grayscale object-cover w-full ', {
                  'w-1/2 lg:max-h-full': person.Story_image.length > 1
                })}
              />
            );
          })}
        </div>
        <div className='md:flex'>
          <div className='md:w-1/4 flex-shrink-0 md:mr-6'>
            <h4 className='text-lg md:text-xl lg:text-2xl leading-tight'>
              {person.profile_name}
            </h4>
            <h5 className='text-lg md:text-xl lg:text-2xl leading-tight opacity-50'>
              {person.profile_title}
            </h5>
          </div>
          <div className='pt-6 md:p-0'>
            <div className='flex'>
              <div className='text-lg md:text-xl lg:text-2xl leading-tight'>
                <h3>“{person.story_title}”</h3>
              </div>
            </div>
            <div className='text-lg md:text-xl lg:text-2xl leading-tight opacity-50'>
              <div>{parseMarkdown(person.story_body?.data?.story_body)}</div>
            </div>
          </div>
        </div>
      </section>
    ));
  };

  return (
    <Page
      className='page-people dark relative bg-black'
      meta={{
        title: page.seotitle,
        description: page.seodescription,
        image: page.preview_img[0]?.url
      }}
      drid={drids.PAGE.PEOPLE}
      isDark
    >
      <div className='header-spacer h-24' />
      <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
        <div className='lg:w-80 lg:mr-12 flex-shrink-0'>
          <div className='buggy-align text-3xl flex justify-center text-center md:text-left md:justify-start pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2 className='font-title'>Humans of DevRev</h2>
          </div>
          <div className='buggy-align mx-auto w-5/6 md:w-full mb-12 lg:m-0 max-w-xl text-center md:text-left text-lg md:text-xl px-3 md:px-0 lg:text-2xl lg:max-width-none leading-tight'>
            <p>Every DevReveler has a unique story of why they are here.</p>
          </div>
        </div>
        <div className='space-y-12 md:space-y-24'>{renderPeople()}</div>
      </div>
    </Page>
  );
}

export const query = graphql`
  query PagePeopleQuery {
    strapiDevreveler {
      seodescription
      seotitle
      preview_img {
        url
      }
    }
    allStrapiDevReveler {
      nodes {
        profile_name
        profile_title
        story_body {
          data {
            story_body
          }
        }
        story_title
        order
        anchor
        profile_memoji {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
        Story_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
            }
          }
        }
      }
    }
  }
`;
